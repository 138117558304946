import Link from "next/link";
import { Icons8ArrowRight, Icons8checkmark } from "~/src/components/icons";
import Icon8Checkmark from "~/src/components/icons/Icon8checkmark";

export interface FaqItem {
  id: number;
  question: string;
  answer: string | JSX.Element;
}

export interface FaqSectionData {
  title: string;
  items: FaqItem[];
}

/* INFO ABOUT DATA
Sections and ID ranges covered by these sections:
General, 1-10
Smoke, 11-20
Electrical, 21-40
Gas, 41-60
Caravan Gas Compliance Check, 61-70
Switchboard Upgrade, 71-80
Interconnected Smoke Alarm, 81-90
Property Compliance Service, 91-100
Solutions for Landlords, 101-110
Solutions for Property Managers, 111-120
Solutions for Homeowners, 121-130
Solutions for Building Managers, 131-140
Solutions for Real Estate, 141-150
Legislation Page Elements, 151-160*/

const FaqSectionsData: FaqSectionData[] = [
  {
    title: "General FAQ",
    items: [
      {
        id: 1,
        question: "When can I book an appointment for?",
        answer: (
          <>
            <p>
              Our appointment times are flexible. RCSC staff are available
              Monday through Friday, 08:00 to 16:00.
            </p>
          </>
        ),
      },
      {
        id: 2,
        question: "How long do appointments take?",
        answer: (
          <>
            <p className="mb-2">
              The duration of each safety check is as follows:
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>Electrical checks: 1-2 hours</li>
              <li>
                Gas safety checks: 1-3 hours, depending on the number of
                appliances.
              </li>
              <li>Smoke alarm checks: ~15 minutes</li>
            </ul>
            However, these times can vary for a number of different reasons,
            such as the number of appliances or the need for urgent repairs.
          </>
        ),
      },
      {
        id: 3,
        question: "How should I prepare for my appointment?",
        answer: (
          <>
            <p className="mb-2">
              Before your appointment, RCSC staff provide instructions on how to
              prepare based on your property and the safety checks requested.
            </p>
          </>
        ),
      },
      {
        id: 4,
        question:
          "What are the potential risks of not conducting routine eletrical, gas, and smoke alarm safety checks?",
        answer: (
          <>
            <p className="mb-2">
              In Victoria, there are state-wide regulations regarding safety
              checks on properties. Failure to comply with them can lead to
              serious legal consequences, as well as endanger the lives of
              occupants.
            </p>
            <p>
              Electrical and gas safety checks must be performed every two
              years, while smoke alarm safety checks must be performed annually.
              All safety checks must be performed by licensed professionals.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Smoke Alarm Safety FAQ",
    items: [
      {
        id: 11,
        question:
          "Whose responsibility is it to maintain smoke alarms in rental properties?",
        answer: (
          <>
            <p>
              According to the Residential Tenancies Regulations 2021, the
              Residential Rental Provider (Landlord) is responsible for:
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                Ensuring smoke alarms are correctly installed and maintained.
              </li>
              <li>Each smoke alarm must be tested once a year.</li>
              <li>Replacing batteries as necessary.</li>
            </ul>
            <p>
              The Renter (Tenant) must inform the Resident Rental Provider in
              writing if a smoke alarm isn&apos;t working.
            </p>
          </>
        ),
      },
      {
        id: 12,
        question:
          "Are landlords required to hire a contractor for smoke alarm maintenance?",
        answer: (
          <>
            <p className="mb-2">
              The Real Estate Institute of Victoria (REIV) recommends that
              Residential Rental Providers (Landlords) have smoke alarms in
              their rental properties regularly maintained by a contractor.
            </p>
            <p>
              Registered contractors are insured for both public liability and
              professional indemnity, which helps to minimise risk.
            </p>
          </>
        ),
      },

      {
        id: 13,
        question: "How many smoke alarms are needed in residential properties?",
        answer: (
          <>
            <p>
              According to the law, all residential properties in Victoria must
              have smoke alarms installed on every level at a minimum. The exact
              number depends on the floor plan.
            </p>
            <p>
              Smoke alarms must be in line with the Australian standard AS 3786.
            </p>
          </>
        ),
      },
      {
        id: 14,
        question: "Is smoke alarm maintenance just about replacing batteries?",
        answer: (
          <>
            <p className="mb-2">
              Periodic maintenance of smoke alarms is essential as they can
              become less effective for a variety of reasons, including dust and
              insects.
            </p>
            <p>
              Smoke alarms must be replaced at least every ten years or earlier,
              depending on their expiration date.
            </p>
          </>
        ),
      },
      {
        id: 15,
        question:
          "How often should I have a smoke alarm safety check performed?",
        answer: (
          <>
            <p>
              As per the Residential Tenancies Regulations 2021, each smoke
              alarm must be tested once a year.
            </p>
          </>
        ),
      },
      {
        id: 16,
        question: "Is the service applicable to 240 V hard-wired smoke alarms?",
        answer: (
          <>
            <p>
              240V smoke alarms have backup batteries that need to be replaced,
              and they must be cleaned and tested in the same way as 9V
              battery-operated alarms. Additionally, 240V smoke alarms have
              expiration dates, and they must be replaced after they expire.
            </p>
          </>
        ),
      },
      {
        id: 17,
        question:
          "What type of smoke alarms will be installed if my alarms are replaced?",
        answer: (
          <>
            <p>
              RCSC technicians use photoelectric smoke alarms. These alarms are
              standard for most residential applications.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Electrical Safety FAQ",
    items: [
      {
        id: 21,
        question:
          "What's an electrical safety check, and how often is one necessary?",
        answer: (
          <>
            <p className="mb-2">
              As defined by the Residential Tenancies Regulations 2021, an
              electrical safety check is an inspection of all provided
              electrical installations, fixtures, and fittings in accordance
              with Section 4 of AS/NZS 3019.
            </p>
            <p>
              As of 29 March 2021, it&apos;s mandatory to have an electrical
              safety check performed every two years by a licensed or registered
              electrician.
            </p>
          </>
        ),
      },
      {
        id: 22,
        question:
          "Who's responsible for ensuring that my rental property has recently undergone an electrical safety check?",
        answer: (
          <>
            <p className="mb-2">
              According to the Residential Tenancies Regulations 2021, it&apos;s
              the responsibility of the Residential Rental Provider (Landlord).
            </p>
            <p>
              Upon request, the landlord must provide the tenant with a written
              document stating the date of the most recent safety check.
            </p>
          </>
        ),
      },
      {
        id: 23,
        question: "Who's authorised to perform an electrical safety check?",
        answer: (
          <>
            According to the law, an electrical safety check can only be
            conducted by a licensed or registered electrician.
          </>
        ),
      },
      {
        id: 24,
        question: "What areas will the electrician need access to?",
        answer: (
          <>
            The law states that an electrical safety check includes the
            examination of all provided electrical installations, fixtures, and
            fittings. This includes power outlets, light switches, switchboards,
            and electrical appliances owned by the landlord.
          </>
        ),
      },
      {
        id: 25,
        question: "Why do I need to have an electrical safety check performed?",
        answer: (
          <>
            {" "}
            <p>
              An electrical safety check can help identify potential hazards and
              ensure that the electrical system functions safely, protecting
              both your property and the people residing there.
            </p>
          </>
        ),
      },
      {
        id: 26,
        question:
          "How often should I have an electrical safety check performed?",
        answer: (
          <>
            <p>
              As of 29 March 2021, it&apos;s mandatory for landlords to ensure
              that all provided electrical appliances, installations, and
              fittings undergo an electrical safety check by a licensed or
              registered electrician every two years.
            </p>
          </>
        ),
      },
      {
        id: 27,
        question: "What does an electrical safety check involve?",
        answer: (
          <>
            <p className="mb-2">
              An electrical safety check involves thoroughly inspecting the
              property&apos;s electrical system, including the wiring,
              switchboards, and electrical appliances.
            </p>
            <p>
              A detailed report outlining the results of the safety check will
              be provided, along with a quote for any necessary repairs or
              upgrades.
            </p>
          </>
        ),
      },
      {
        id: 28,
        question: "Can I perform an electrical safety check myself?",
        answer: (
          <>
            {" "}
            <p>
              No, only a licensed professional can perform an electrical safety
              check.
            </p>
          </>
        ),
      },
      {
        id: 29,
        question: "How long does an electrical safety check take?",
        answer: (
          <>
            {" "}
            <p>
              A typical electrical safety check takes one to three hours.
              However, the total time can vary depending on a number of factors,
              including the size of the property and the complexity of the
              electrical system.
            </p>
          </>
        ),
      },
      {
        id: 30,
        question:
          "What happens if the property's electrical system fails the safety check?",
        answer: (
          <>
            <p>
              If the electrical system fails the safety check, the electrician
              will provide you with a detailed report outlining the issues and
              the necessary repairs or upgrades. They may also provide a quote
              for this work.
            </p>
          </>
        ),
      },
      {
        id: 31,
        question:
          "Are there any signs that indicate I need an electrical safety check?",
        answer: (
          <>
            <p>Signs an electrical safety check is needed include:</p>
            <ul className="mb-1 list-disc space-y-1 pl-4">
              <li>Frequent power outages</li>
              <li>Circuit breakers frequently tripping</li>
              <li>Flickering lights</li>
              <li>Discoloured power points</li>
              <li>Burning smells</li>
              <li>Buzzing sounds</li>
            </ul>
            <p>
              If you notice and of these, organise an electrical safety check as
              soon as possible.
            </p>
          </>
        ),
      },
      {
        id: 32,
        question:
          "How much disruption does an electrical safety check cause to my day?",
        answer: (
          <>
            {" "}
            <p>
              An electrical safety check can take several hours, and during this
              time, it may be necessary to turn off power to different areas of
              your property. However, RCSC does everything possible to keep
              disruptions to a minimum.
            </p>
          </>
        ),
      },
      {
        id: 33,
        question: "Do I need to be present during the electrical safety check?",
        answer: (
          <>
            <p>
              You&apos;re not required to be present for the electrical safety
              check, but it is recommended. You&apos;ll be able to ask the
              electrician any questions and, if any issues are detected, discuss
              the best and fastest ways to fix them.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Gas Safety FAQ",
    items: [
      {
        id: 41,
        question:
          "What is a gas safety check, and how often should one be performed?",
        answer: (
          <>
            <p className="mb-2">
              Gas safety checks only apply to rental properties with gas
              appliances, fixtures, or fittings. These inspections must comply
              with Australian Standards AS/NZ 5601 and AS 4575.{" "}
            </p>
            <p className="mb-2">
              These safety checks and tests must comply with Australian
              Standards AS/NZ 5601 and AS 4575.
            </p>
            <p className="mb-2">
              Gas safety checks only apply to rental properties with gas
              appliances, fixtures, or fittings.
            </p>
          </>
        ),
      },
      {
        id: 42,
        question:
          "Who's responsible for conducting gas safety checks in rental properties?",
        answer: (
          <>
            <p className="mb-2">
              According to the Residential Tenancies Regulations 2021, the
              Residential Rental Provider (Landlord) is responsible for ensuring
              that all gas installations and fittings in the rented premises
              undergo a gas safety check every two years by a licensed or
              registered gasfitter.
            </p>
            <p>
              Upon request, the landlord must provide the tenant with a document
              detailing the date of the most recent safety check.
            </p>
          </>
        ),
      },
      {
        id: 43,
        question: "Who's authorised to conduct a gas safety check?",
        answer: (
          <>
            Gas safety checks must be carried out by a licensed or registered
            gasfitter. A licensed or registered gasfitter should be certified in
            Type A gas servicing work, as indicated by the endorsement &apos;Gas
            Serving Type A&apos; on the plumber&apos;s identity card.
          </>
        ),
      },
      {
        id: 44,
        question: "What does a gas safety check involve?",
        answer: (
          <>
            {" "}
            <p className="mb-2">
              During a gas safety check, a licensed gasfitter will:
            </p>
            <ul className="mb-2 list-disc space-y-2 pl-5">
              <li>Inspect all gas appliances and pipework on the property.</li>
              <li>
                Check for any signs of gas leaks or carbon monoxide emissions.
              </li>
              <li>
                Ensure the ventilation and flues are clear and functioning
                correctly.{" "}
              </li>
            </ul>
            <p className="mb-2">
              The gasfitter may also conduct tests to ensure that gas appliances
              operate at the correct pressures and gas consumption rates.
            </p>
            After the check, the gasfitter will issue a Gas Safety Certificate
            if the appliances and pipework comply with gas safety regulations.
          </>
        ),
      },
      {
        id: 45,
        question: "How long does a gas safety check take?",
        answer: (
          <>
            <p>
              A gas safety check can take anywhere from one to two hours,
              depending on a number of factors, such as the size of the property
              and the number of gas installations.
            </p>
          </>
        ),
      },
      {
        id: 46,
        question: "Can I perform a gas safety check myself?",
        answer: (
          <>
            <p>
              Only a licensed gasfitter with the required training and equipment
              is able to perform a gas safety check.
            </p>
          </>
        ),
      },

      {
        id: 47,
        question: "What should I do if I smell gas in my home?",
        answer: (
          <>
            <p>Gas leaks can be extremely dangerous. If you smell gas:</p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                Do not use electrical switches or devices, as this could cause a
                spark and ignite the gas.
              </li>
              <li>Turn off all gas appliances.</li>
              <li>Open all the doors and windows.</li>
              <li>
                Turn off the gas supply at the metre or cylinder (if possible).
              </li>
              <li>
                Call a licensed gasfitter or emergency gas service as soon as
                possible.{" "}
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 48,
        question:
          "How much disruption will a gas safety check cause to my day?",
        answer: (
          <>
            <p className="mb-2">
              A gas safety check can sometimes cause minor disruption as the
              gasfitter will need access to all gas appliances and pipework.
            </p>
            <p>
              Provided that the gasfitter can access these areas, disruptions
              should be kept to a minimum.
            </p>
          </>
        ),
      },
      {
        id: 49,
        question: "What's carbon monoxide, and why is it dangerous?",
        answer: (
          <>
            {" "}
            <p className="mb-2">
              Carbon monoxide (CO) is a gas that has no odour, taste, or colour,
              making it impossible to detect without special equipment.
              It&apos;s created when fuels are incompletely burned.
            </p>
            <p className="mb-2">
              When inhaled, the carbon monoxide molecules replace the oxygen in
              the blood and cause carbon monoxide poisoning. The symptoms of
              carbon monoxide poisoning present in many different ways and are
              often mistaken for those of a cold, the flu, or even food
              poisoning.
            </p>
            <p className="mb-1 mt-1">
              Symptoms may include headaches, coordination difficulties, nausea
              and vomiting, weakness, hot flushes, muscle pain, dizziness,
              shortness of breath, confusion, or chest pain.
            </p>
          </>
        ),
      },
      {
        id: 50,
        question: "What appliances can emit carbon monoxide?",
        answer: (
          <>
            {" "}
            <p className="mb-2">
              Carbon monoxide can be emitted by any gas appliance.
            </p>
            <p className="mb-2">
              Gas heaters can produce significant amounts of carbon monoxide if
              improperly maintained. Never leave them running overnight, as this
              increases the chance of carbon monoxide poisoning.
            </p>
            <p className="mb-2">
              Never use portable outdoor gas appliances indoors, as they can
              also produce carbon monoxide. The lack of ventilation can prove
              deadly.
            </p>
            For further information, we recommend visiting{" "}
            <Link
              href="https://www.esv.vic.gov.au/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
            >
              Energy Safe Victoria
            </Link>
          </>
        ),
      },

      {
        id: 51,
        question:
          "What are the consequences of not having regular gas safety checks?",
        answer: (
          <>
            <p className="mb-2">
              By law, in Victoria, gas appliances must be inspected and serviced
              every two years by a licensed gasfitter.
            </p>{" "}
            <p>
              Failure to have these checks performed not only risks lives but
              may also result in legal action, including hefty fines.
            </p>
          </>
        ),
      },
      {
        id: 52,
        question: "Why is my PTR line non-compliant?",
        answer: (
          <>
            <p>
              The pressure and temperature relief valve is a very important part
              of the hot water heating system. As water from this valve and
              drain line can be at temperatures of up to 99 degrees, it must be
              discharged in a safe place. A dangerous location is the most
              common reason for PTR line non-compliance.
            </p>
          </>
        ),
      },
      {
        id: 53,
        question: "What does it mean if my heat exchanger is cracked?",
        answer: (
          <>
            <p>
              The heat exchanger of a gas heater must be in good condition and
              free of cracks to prevent it from emitting carbon monoxide. If
              your heater has been capped off due to a crack in the heat
              exchanger, it has been done so for safety reasons.
            </p>
          </>
        ),
      },
      {
        id: 54,
        question: "What's negative pressure?",
        answer: (
          <>
            <p>
              Negative pressure is an effect caused by exhaust fans and range
              hoods on gas appliances. If an appliance is operated at the same
              time as the exhaust fans, there&apos;s a chance poisonous gases
              could enter the property due to the vacuum effect created by the
              exhausts.
            </p>
          </>
        ),
      },
      {
        id: 55,
        question:
          "What's the difference between safety-critical and non-safety-critical non-compliance?",
        answer: (
          <>
            <p>
              Safety-critical non-compliance means the gas installation is
              unsafe, and immediate action is required to rectify the issue.
              Non-safety-critical non-compliance means the gas installation is
              safe, and no immediate action is required; however, it&apos;s
              recommended that the installation is repaired so that an issue
              can&apos;t cause further damage.
            </p>
          </>
        ),
      },
      {
        id: 56,
        question: "Why do I need to relocate my gas appliance regulator?",
        answer: (
          <>
            <p>
              To carry out a full service of a gas appliance and/or gas
              installation, the regulator must be in an easily accessible
              position to conduct pressure testing and to carry out adjustments
              if required.
            </p>
          </>
        ),
      },
      {
        id: 57,
        question:
          "Why was the heater in my roof not serviced as part of the safety check?",
        answer: (
          <>
            <p>
              Access may have been an OH&S issue as per regulation AS/NZS5601.1.
              Gas appliances in roofs must have a platform that runs from the
              point of access to and around the heater.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Caravan Gas Compliance FAQ",
    items: [
      {
        id: 61,
        question: "What does a gas safety check for a caravan involve?",
        answer: (
          <>
            <p>
              A caravan gas safety check is a comprehensive inspection of the
              caravans gas installations, appliances and fittings, all
              inspections are carried out in accordance with AS/NZS 5601 Part 1
              & Part 2, occasionally your caravan may require further works to
              bring it up to standards prior to issuing a compliance
              certificate.
            </p>
          </>
        ),
      },
      {
        id: 62,
        question: "Why is gas safety compliance important for my caravan?",
        answer: (
          <>
            <p>
              Gas safety compliance ensures your caravan&apos;s gas system is
              safe and meets legal requirements. It&apos;s also important for
              vehicle registration, as compliance needs to be verified each time
              the caravan is sold or undergoes gas system modifications.
            </p>
          </>
        ),
      },
      {
        id: 63,
        question:
          "Who's qualified to conduct a gas safety inspection in a caravan?",
        answer: (
          <>
            <p>
              A licensed gasfitter will check that the gas installations and
              appliances meet safety and regulatory standards.
            </p>
          </>
        ),
      },
      {
        id: 64,
        question: "How often should I have my caravan's gas system inspected?",
        answer: (
          <>
            <p>
              Ideally, inspections should occur at least annually.Your
              caravan&apos;s gas system needs to be inspected whenever you make
              changes to your gas appliances, have repair work done, or when
              selling your caravan.
            </p>
          </>
        ),
      },
      {
        id: 65,
        question:
          "What preparations do I need to make for a caravan gas safety inspection?",
        answer: (
          <>
            <p className="mb-2">Ensure that:</p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>Your caravan is on level ground</li>
              <li>The caravan must have a gas test point installed.</li>
              <li>The gas bottle is filled.</li>
              <li>All appliances function correctly.</li>
              <li>
                You have a 2-stage regulator with overpressure protection.{" "}
              </li>
              <li>Only use Australian-certified appliances.</li>
            </ul>
          </>
        ),
      },
      {
        id: 66,
        question: "Are there any rules about storing gas in a caravan? ",
        answer: (
          <>
            <p>
              Gas should be stored in a compartment that&apos;s outside the
              caravan, sealed against gas entry, well-ventilated, and
              corrosion-resistant. It should display a warning sign and only
              contain secured gas cylinders and associated fittings.
            </p>
          </>
        ),
      },
      {
        id: 67,
        question: "What happens if my caravan fails the compliance check?",
        answer: (
          <>
            <p>
              If your caravan doesn&apos;t pass the compliance check, you will
              receive a report outlining the additional work needed to bring
              your installation up to compliance. You have the option of hiring
              RCSC or another qualified service provider to perform this work.
            </p>
          </>
        ),
      },
      {
        id: 68,
        question: "How do different types of gas affect caravan compliance?",
        answer: (
          <>
            <p>
              Different types of gas, such as propane and butane, have different
              regulations regarding storage, pressure settings, and appliance
              compatibility. Compliance checks ensure that your caravan&apos;s
              system is correctly configured for the type of gas used.
            </p>
          </>
        ),
      },
      {
        id: 69,
        question:
          "What specific safety devices are checked during a caravan gas compliance inspection?",
        answer: (
          <>
            <p>
              During an inspection, safety devices such as gas detectors,
              emergency shut-off valves and pressure regulators are thoroughly
              checked.
            </p>
          </>
        ),
      },
      {
        id: 70,
        question: "How important is caravan ventilation for gas compliance?",
        answer: (
          <>
            <p>
              Proper ventilation is critical in caravans to prevent the buildup
              of hazardous gas fumes. Compliance checks ensure that any
              ventilation system functions correctly and meets legal standards.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Electrical Switchboard Upgrade FAQ",
    items: [
      {
        id: 71,
        question:
          "What's a switchboard, and why is it essential to upgrade it?",
        answer: (
          <>
            <p className="mb-2">
              A switchboard controls the flow of electricity and distributes it
              to circuits and appliances. Upgrading a switchboard ensures it can
              handle this electrical load. It also helps to improve safety by
              mitigating the risk of electrical fires or shocks. An outdated or
              overloaded switchboard can pose a significant risk to the
              building&apos;s occupants.
            </p>
            <p className="mb-2">
              Upgrading the switchboard can also improve energy efficiency,
              reduce bills, and increase the value of a property.
            </p>
            <p>
              If your property is found to be in breach of switchboard
              regulations, you may face legal consequences such as fines.
            </p>
          </>
        ),
      },
      {
        id: 72,
        question:
          "What are the key changes introduced in the new regulation, and how do they impact switchboard upgrades?",
        answer: (
          <>
            <p className="mb-2">
              The new regulations in Victoria mandate that all switchboards
              comply with the latest Australian standards for electrical safety.
              Switchboards must now be fitted with arc fault detection devices
              (AFDDs) to prevent arcs from causing electrical fires. Residual
              current devices (RCDs) must also be installed to disconnect the
              power if a fault is detected.
            </p>
            <p>
              The installation or upgrade process may involve updating the
              wiring, switches and other switchboard components to meet the new
              requirements.
            </p>
          </>
        ),
      },
      {
        id: 73,
        question:
          "How do I know if my switchboard needs an upgrade to comply with the new regulation?",
        answer: (
          <>
            <p className="mb-2">
              Common signs that your switchboard may need an upgrade include
              circuit breakers tripping, flickering lights, burning smells, or
              sparking switches.
            </p>
            <p>
              To determine if your switchboard needs an upgrade to comply with
              new regulations, it&apos;s best to consult with a licensed
              electrician.
            </p>
          </>
        ),
      },
      {
        id: 74,
        question:
          "What are the potential consequences of not upgrading my switchboard?",
        answer: (
          <>
            <p>
              Failure to upgrade your switchboard to comply with the new
              regulations will result in legal penalties such as fines. It also
              poses a significant risk to the property&apos;s occupants.
            </p>
            <p>
              Additionally, if an electrical incident occurs due to an outdated
              or non-compliant switchboard, your insurance may not cover the
              damages, and you could be liable for any injuries or property
              damage caused.
            </p>
          </>
        ),
      },
      {
        id: 75,
        question:
          "Can I upgrade my switchboard on my own, or do I need to hire a licensed electrician?",
        answer: (
          <>
            <p className="mb-2">
              Only licensed electricians have the expertise to safely and
              legally carry out switchboard upgrades.
            </p>
            <p>
              Attempting to upgrade a switchboard on your own could pose a
              significant risk of injury or property damage and may result in
              non-compliance with the new regulations.
            </p>
          </>
        ),
      },
      {
        id: 76,
        question:
          "How often should I upgrade my switchboard to ensure compliance with the latest regulations?",
        answer: (
          <>
            <p className="mb-2">
              In Victoria, Australia, the regulations and requirements for
              switchboard upgrades are always subject to change, so you need to
              be vigilant in checking for any new legislation.
            </p>
            <p>The latest regulations came into effect on 29 March 2023.</p>
          </>
        ),
      },
    ],
  },
  {
    title: "Interconnected Smoke Alarms FAQ",
    items: [
      {
        id: 81,
        question: "What's an interconnected smoke alarm?",
        answer: (
          <>
            {" "}
            <p>
              An interconnected smoke alarm is a system where multiple smoke
              alarms are linked so that, if one detects smoke, all the alarms
              sound simultaneously. This interconnectedness ensures that people
              in all areas of a property are alerted prompty.
            </p>
          </>
        ),
      },
      {
        id: 82,
        question:
          "How do interconnected smoke alarms differ from traditional smoke alarms?",
        answer: (
          <>
            <p>
              Traditional smoke alarms operate independently. In contrast,
              interconnected alarms communicate with each other, ensuring that
              if one alarm is triggered, every alarm in the system sounds. This
              increases the chances of early detection and evacuation.
            </p>
          </>
        ),
      },
      {
        id: 83,
        question:
          "Why should I consider installing interconnected smoke alarms in my property?",
        answer: (
          <>
            {" "}
            <p>
              Interconnected alarms provide enhanced safety. If a fire starts in
              a remote part of a property, residents in other areas are alerted
              immediately, increasing evacuation time. This can be especially
              important in larger homes or properties.
            </p>
          </>
        ),
      },
      {
        id: 84,
        question:
          "How do interconnected smoke alarms communicate with each other?",
        answer: (
          <>
            {" "}
            <p>
              Most modern interconnected alarms communicate wirelessly, while
              some older systems may use hard-wired connections. This
              communication ensures synchronous alerts across alarms, creating a
              networked safety system.
            </p>
          </>
        ),
      },
      {
        id: 85,
        question:
          "What power sources can interconnected smoke alarms operate on: batteries, mains power, or both?",
        answer: (
          <>
            {" "}
            <p>
              They can operate on both. Many interconnected alarms are
              hard-wired to the mains power with a battery backup, ensuring they
              work even during power outages. This dual-power approach maximises
              reliability.
            </p>
          </>
        ),
      },
      {
        id: 86,
        question:
          "How many interconnected smoke alarms can be linked together in a single system?",
        answer: (
          <>
            {" "}
            <p>
              It varies by brand and model, but commonly, up to 12-24 alarms can
              be interconnected in one system. This range allows them to cater
              to properties of different sizes.
            </p>
          </>
        ),
      },
      {
        id: 87,
        question:
          "Are interconnected smoke alarms compatible with smart home systems or apps?",
        answer: (
          <>
            {" "}
            <p>
              Yes, many modern interconnected alarms can integrate with smart
              home systems, allowing remote monitoring and notifications via
              smartphone apps. This feature adds an additional layer of
              convenience and security.
            </p>
          </>
        ),
      },
      {
        id: 88,
        question:
          "What considerations should be made when positioning interconnected smoke alarms throughout a property?",
        answer: (
          <>
            {" "}
            <p>
              Alarms should be placed in every bedroom, outside sleeping areas,
              and on every level of the home. Avoid placing them near kitchens
              or bathrooms to reduce false alarms from steam or cooking smoke.
              Proper placement ensures effective coverage and reduces nuisances.
            </p>
          </>
        ),
      },
      {
        id: 89,
        question:
          "How frequently do interconnected smoke alarms need maintenance or testing?",
        answer: (
          <>
            {" "}
            <p>
              Test interconnected alarms monthly using the test button. Replace
              batteries annually (unless they&apos;re long-life batteries) and
              replace the entire unit every 10 years or as recommended by the
              manufacturer. Regular testing and maintenance keep the system
              optimal.
            </p>
          </>
        ),
      },
      {
        id: 90,
        question:
          "Are there specific brands or types of interconnected smoke alarms recommended for certain types of buildings or conditions?",
        answer: (
          <>
            {" "}
            <p>
              Recommendations can vary based on regional regulations and
              building types. Always check local guidelines and consult with
              fire safety professionals when choosing a system. Different
              structures might have unique requirements.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Property Compliance Subscription FAQ",
    items: [
      {
        id: 91,
        question: "What is the Property Compliance Subscription?",
        answer: (
          <>
            {" "}
            <p>
              The Property Compliance Subscription offers regular electrical,
              gas and smoke alarm safety checks as a package for a discounted
              annual fee. It&apos;s a simple and effective solution.
            </p>{" "}
            <p>
              The Property Compliance Subscription is available only for rental
              properties.
            </p>
          </>
        ),
      },
      {
        id: 92,
        question: "How often are the safety checks conducted on my property?",
        answer: (
          <>
            <p>
              This service includes:
              <ul className="list-disc space-y-2 pl-5">
                <li>An electrical safety check every two years.</li>
                <li>A gas safety check every two years.</li>
                <li>A smoke alarm safety check every year.</li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: 93,
        question:
          "What kind of service calls are covered under the Property Compliance Subscription?",
        answer: (
          <>
            Service calls for verified safety issues related to gas and
            electrical installations, appliances and fittings are covered under
            the subscription. This includes problems with gas appliances, pilot
            light reignition, switchboards and power points.
          </>
        ),
      },
      {
        id: 94,
        question:
          "What happens if there's a recurring issue with my gas or electrical appliance?",
        answer: (
          <>
            We will provide solutions for any reported appliance issues.
            However, we reserve the right to exclude recurring appliance issues
            from the service if RCSC&apos;s repair recommendations are declined.
          </>
        ),
      },
      {
        id: 95,
        question:
          "Are there any complimentary parts included in the subscription?",
        answer: (
          <>
            Yes, certain complementary parts are included, such as thermocouple
            replacements for gas services, standard safety switches, light
            switches, power points, fuses, and circuit breakers for electrical
            services.
          </>
        ),
      },
      {
        id: 96,
        question:
          "What's not covered in the subscription regarding electrical installations and appliances?",
        answer: (
          <>
            The subscription doesn&apos;t cover general faults in electrical
            installations, appliances and fittings, as well as issues with
            lighting or the replacement of light bulbs.
          </>
        ),
      },
      {
        id: 97,
        question:
          "What kind of materials are provided for free under the subscription?",
        answer: (
          <>
            <p className="mb-2">
              For gas safety, examples include control valve replacements,
              temporary heaters and lagging of exposed PEX pipework.
            </p>
            <p className="mb-2">
              For electrical safety, the materials include standard switch and
              socket outlet.
            </p>
          </>
        ),
      },
      {
        id: 98,
        question: "When does the subscription start?",
        answer: <>It begins on the invoice issuance date.</>,
      },
      {
        id: 99,
        question: "When are the services executed?",
        answer: (
          <>
            Services start as soon as the subscription becomes active. However,
            they&apos;re not normally executed on the subscription start date
            itself in order to avoid operational difficulties.
          </>
        ),
      },
      {
        id: 100,
        question: "How is payment for services processed?",
        answer: (
          <>
            For those affiliated with our partner agencies, we coordinate
            payments through them. Other clients receive invoices directly from
            us.
          </>
        ),
      },
    ],
  },
  {
    title: "Solutions for Landlords FAQ",
    items: [
      {
        id: 101,
        question:
          "What safety standards should I be aware of for electrical systems, gas appliances, and smoke alarms in my rental properties?",
        answer: (
          <>
            <p>
              As a Landlord, you must uphold the Residential Tenancies
              Regulations 2021 by observing the following:
              <ul className="mb-1 list-disc space-y-2 pl-5">
                <li>
                  Safety Checks for Electrical and Gas Installations: Both
                  electrical and gas systems should undergo safety checks by
                  qualified professionals every two years. Tenants are entitled
                  to know the dates of the latest checks upon request.
                </li>
                <li>
                  Smoke Alarms: They must be correctly installed, maintained,
                  and tested according to the manufacturer&apos;s instructions
                  at least once a year. Batteries should be replaced when
                  required. If a smoke alarm is found to be defective, it&apos;s
                  the tenant&apos;s responsibility to report it in writing.
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: 102,
        question: "When can I schedule an appointment?",
        answer: (
          <>
            <p>
              You can schedule an appointment Monday to Friday, between 08:00
              and 16:00.
            </p>
            <p>Saturdays are available by prior arrangement only.</p>
          </>
        ),
      },
      {
        id: 103,
        question: "How long does each appointment take?",
        answer: (
          <>
            <p>
              The duration of the appointment depends on the service:
              <ul className="mb-1 list-disc space-y-2 pl-5">
                <li>Electrical checks: 1-2 hours</li>
                <li>Gas safety checks: 1-3 hours</li>
                <li>Smoke alarm checks: ~15 minutes</li>
              </ul>
              However, these times can vary for several reasons, such as the
              number of appliances or the need for urgent repairs.
            </p>
          </>
        ),
      },
      {
        id: 104,
        question:
          "What service do you suggest for busy landlords who want to ensure their compliance with current regulations?",
        answer: (
          <>
            {" "}
            <p>
              We recommend our{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>{" "}
              . This service is designed to maintain compliance with the latest
              regulations, particularly around safety checks. It removes the
              hassle of remembering deadlines and ensures everything is always
              up-to-date.
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                All-In-One Service: Fewer repeat visits, guaranteed lowest
                prices, and swift problem resolution.
              </li>
              <li>
                Hands-Free Management: Set and forget. We handle urgent repairs
                and regular checks, providing a clear audit trail.
              </li>
              <li>
                Transparent Pricing: A consistent annual fee after the first
                year for hassle-free compliance management.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 105,
        question:
          "As a landlord, what are the consequences of neglecting to conduct consistent electrical, gas and smoke alarm safety inspections at rental properties?",
        answer: (
          <>
            <p>
              In Victoria, Landlords are legally required to ensure licensed
              professionals perform electrical and gas safety checks on all
              their properties every two years. Failure to do so will result in
              serious legal consequences and may endanger the tenants&apos;
              lives.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Solutions for Property Managers FAQ",
    items: [
      {
        id: 111,
        question:
          "What safety standards should I be aware of for electrical systems, gas appliances and smoke alarms in my rental units?",
        answer: (
          <>
            <p>
              As a Property Manager, it&apos;s essential to uphold the
              Residential Tenancies Regulations 2021 for the Landlords you
              represent:
            </p>

            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                Safety Checks for Electrical and Gas Installations: Both
                electrical and gas systems should undergo safety checks by
                qualified professionals every two years. Tenants are entitled to
                know the dates of the latest checks upon request.
              </li>
              <li>
                Smoke Alarms: They must be correctly installed, maintained, and
                tested according to the manufacturer&apos;s instructions at
                least once a year. Batteries should be replaced when required.
                If a smoke alarm is found to be defective, it&apos;s the
                tenant&apos;s responsibility to report it in writing.
              </li>
            </ul>

            <p>
              We provide both individual safety checks and an annual
              subscription-based service. This service covers all three safety
              checks and is bundled with other benefits to make managing your
              properties more convenient.{" "}
            </p>
            <p>
              Learn more about the{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              , remove the hassle of remembering deadlines, and ensure
              everything is always up-to-date.
            </p>
          </>
        ),
      },
      {
        id: 112,
        question: "How long do the different safety checks take?",
        answer: (
          <>
            <p>The duration of the safety check depends on the service:</p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>Electrical checks: 1-2 hours</li>
              <li>Gas safety checks: 1-3 hours</li>
              <li>Smoke alarm checks: ~15 minutes</li>
            </ul>
            <p>
              However, these can vary for several reasons, such as the number of
              appliances or the need for urgent repairs.
            </p>
          </>
        ),
      },
      {
        id: 113,
        question: "How should I prepare my property for the safety checks?",
        answer: (
          <>
            <p>
              Our team will provide detailed instructions on how to prepare,
              tailored to your property type and the specific checks to be
              conducted.
            </p>
          </>
        ),
      },
      {
        id: 114,
        question:
          "What service do you suggest for busy property managers who want to ensure their compliance with current regulations?",
        answer: (
          <>
            {" "}
            <p>
              We recommend our{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              . This service is designed to maintain compliance with the latest
              regulations, particularly around safety checks. It removes the
              hassle of remembering deadlines and ensures everything is always
              up-to-date.
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                All-In-One Service: Fewer repeat visits, guaranteed lowest
                prices, and swift problem resolution.
              </li>
              <li>
                Hands-Free Management: Set and forget. We handle urgent repairs
                and regular checks, providing a clear audit trail.
              </li>
              <li>
                Transparent Pricing: A consistent annual fee after the first
                year for hassle-free compliance management.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 115,
        question:
          "What are the potential consequences of not conducting routine electrical, gas, and smoke alarm safety checks?",
        answer: (
          <>
            <p>
              As a Property Manager, it&apos;s essential to uphold the
              Residential Tenancies Regulations 2021 for the Landlords you
              represent. This involves ensuring licensed professionals perform
              electrical and gas safety checks on all their properties every two
              years. Failure to do so will result in serious legal consequences
              and may endanger tenants&apos; lives.
            </p>
            <p>
              {" "}
              To help you stay on top of this, RCSC offers the{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              , a subscription-based service that handles all the safety checks
              on your behalf.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Solutions for Homeowners FAQ",
    items: [
      {
        id: 121,
        question:
          "What safety standards should I be aware of for electrical systems, gas appliances and smoke alarms in my rental units?",
        answer: (
          <>
            <p>
              According to the Residential Tenancies Regulations 2021, your
              obligations include:
              <ul className="mt-1 list-disc space-y-2 pl-5">
                <li>
                  Safety Checks for Electrical and Gas Installations: Both
                  electrical and gas systems should undergo safety checks by
                  qualified professionals every two years. Tenants are entitled
                  to know the dates of the latest checks upon request.
                </li>
                <li className="mb-1">
                  Smoke Alarms: They must be correctly installed, maintained,
                  and tested according to the manufacturer&apos;s instructions
                  at least once a year. Batteries should be replaced when
                  required. If a smoke alarm is found to be defective, it&apos;s
                  the tenant&apos;s responsibility to report it in writing.
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: 122,
        question: "How long does each appointment take?",
        answer: (
          <>
            <p>
              The duration of the appointment depends on the service:
              <ul className="mb-1 mt-1 list-disc space-y-2 pl-5">
                <li>Electrical checks: 1-2 hours.</li>
                <li>Gas safety checks: 1-3 hours</li>
                <li>Smoke alarm checks: ~15 minutes</li>
              </ul>
            </p>
            <p>
              However, these times can vary for several reasons, such as the
              number of appliances or the need for urgent repairs.
            </p>
          </>
        ),
      },
      {
        id: 123,
        question: "How should I prepare my property for the safety checks?",
        answer: (
          <>
            <p>
              Our staff will guide you through any necessary preparations based
              on the services chosen for your specific property.
            </p>
          </>
        ),
      },
      {
        id: 124,
        question:
          "What service do you suggest for busy people who are concerned about compliance?",
        answer: (
          <>
            {" "}
            <p>
              The{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>{" "}
              is recommended. This service manages compliance with safety checks
              and eliminates the hassle of remembering deadlines. It&apos;s an
              all-in-one service that includes immediate coverage, hands-free
              management, emergency readiness, and transparent pricing.
            </p>
          </>
        ),
      },
      {
        id: 125,
        question:
          "What are the potential consequences of not conducting routine smoke alarm, electrical, and gas safety checks?",
        answer: (
          <>
            <p>
              For property owners in Victoria, it&apos;s essential that licensed
              professionals perform electrical and gas safety checks on all
              properties every two years. Failure to do so will result in
              serious legal consequences and may endanger lives.
            </p>
            <p>
              If staying on top of this feels daunting, RCSC offers the{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              , a subscription-based service that takes care of all the safety
              checks on your behalf.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Solutions for Building Managers FAQ",
    items: [
      {
        id: 131,
        question:
          "What safety standards should I be aware of for electrical systems, gas appliances and smoke alarms in my rental units?",
        answer: (
          <>
            <p>
              According to the Residential Tenancies Regulations 2021, your
              obligations involve:
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                Safety Checks for Electrical and Gas Installations: Both
                electrical and gas systems should undergo safety checks by
                qualified professionals every two years. Tenants are entitled to
                know the dates of the latest checks upon request.
              </li>
              <li>
                Smoke Alarms: They must be correctly installed, maintained, and
                tested according to the manufacturer&apos;s instructions at
                least once a year. Batteries should be replaced when required.
                If a smoke alarm is found to be defective, it&apos;s the
                tenant&apos;s responsibility to report it in writing.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 132,
        question: "How long do the different safety checks take?",
        answer: (
          <>
            <p>The duration of the safety check varies based on the service:</p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>Electrical checks: 1-2 hours.</li>
              <li>Gas safety checks: 1-3 hours</li>
              <li>Smoke alarm checks: ~15 minutes</li>
            </ul>
            <p>
              However, these times can vary for several reasons, such as the
              number of appliances or the need for urgent repairs.
            </p>
          </>
        ),
      },
      {
        id: 133,
        question: "How should I prepare my property for the safety checks?",
        answer: (
          <>
            <p>
              Our staff will guide you through any necessary preparations based
              on the services chosen for your specific property.
            </p>
          </>
        ),
      },
      {
        id: 134,
        question:
          "What service do you suggest to busy building managers to ensure their compliance with current regulations?",
        answer: (
          <>
            {" "}
            <p>
              We recommend our{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>{" "}
              . This service is designed to maintain compliance with the latest
              regulations, particularly around safety checks. It removes the
              hassle of remembering deadlines and ensures everything is always
              up-to-date.
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                All-In-One Service: Fewer repeat visits, guaranteed lowest
                prices, and swift problem resolution.
              </li>
              <li>
                Hands-Free Management: Set and forget. We handle urgent repairs
                and regular checks, providing a clear audit trail.
              </li>
              <li>
                Transparent Pricing: A consistent annual fee after the first
                year for hassle-free compliance management.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 135,
        question:
          "What are the potential consequences of not conducting routine electrical, gas and smoke alarm safety checks?",
        answer: (
          <>
            <p className="mb-4">
              Building managers must uphold the Residential Tenancies
              Regulations 2021. This involves ensuring licensed professionals
              perform electrical and gas safety checks on all their properties
              every two years. Failure to do so will result in serious legal
              consequences and may endanger the lives of tenants.
            </p>

            <p>
              To help you stay on top of this, RCSC offers the{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              , a subscription-based service that handles all the safety checks
              on your behalf.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: "Solutions for Real Estate FAQ",
    items: [
      {
        id: 141,
        question:
          "What safety standards should I be aware of for electrical systems, gas appliances and smoke alarms in my rental units?",
        answer: (
          <>
            <p>
              According to the Residential Tenancies Regulations 2021, your
              obligations include:
              <ul className="mb-1 mt-1 list-disc space-y-2 pl-5">
                <li>
                  Safety Checks for Electrical and Gas Installations: Both
                  electrical and gas systems should undergo safety checks by
                  qualified professionals every two years. Tenants are entitled
                  to know the dates of the latest checks upon request.
                </li>
                <li>
                  Smoke Alarms: They must be correctly installed, maintained,
                  and tested according to the manufacturer&apos;s instructions
                  at least once a year. Batteries should be replaced when
                  required. If a smoke alarm is found to be defective, it&apos;s
                  the tenant&apos;s responsibility to report it in writing.
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: 142,
        question: "How long do each of the checks typically take?",
        answer: (
          <>
            <p>
              The duration of the safety check depends on the service:
              <ul className="mb-1 mt-1 list-disc space-y-2 pl-5">
                <li>Electrical checks: 1-2 hours</li>
                <li>Gas safety checks: 1-3 hours</li>
                <li>Smoke alarm checks: ~15 minutes</li>
              </ul>
              However, these times can vary for several reasons, such as the
              number of appliances or the need for urgent repairs.
            </p>
          </>
        ),
      },
      {
        id: 143,
        question: "How should I prepare my property for the safety checks?",
        answer: (
          <>
            <p>
              Our staff will guide you through any necessary preparations based
              on the services chosen for your specific property.
            </p>
          </>
        ),
      },
      {
        id: 144,
        question:
          "What service do you suggest for busy real estate companies who want to ensure their compliance with current regulations?",
        answer: (
          <>
            {" "}
            <p>
              We recommend our{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>{" "}
              . This service is designed to maintain compliance with the latest
              regulations, particularly around safety checks. It removes the
              hassle of remembering deadlines and ensures everything is always
              up-to-date.
            </p>
            <ul className="mb-1 list-disc space-y-2 pl-5">
              <li>
                All-In-One Service: Fewer repeat visits, guaranteed lowest
                prices, and swift problem resolution.
              </li>
              <li>
                Hands-Free Management: Set and forget. We handle urgent repairs
                and regular checks, providing a clear audit trail.
              </li>
              <li>
                Transparent Pricing: A consistent annual fee after the first
                year for hassle-free compliance management.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 145,
        question:
          "What are the potential consequences of not conducting routine electrical, gas and smoke alarm safety checks?",
        answer: (
          <>
            <p className="mb-4">
              The Residential Tenancies Regulations 2021 must be upheld. This
              involves ensuring licensed professionals perform electrical and
              gas safety checks on all their properties every two years. Failure
              to do so will result in serious legal consequences and may
              endanger tenants&apos; lives.
            </p>
            <p>
              To help you stay on top of this, RCSC offers the{" "}
              <Link
                className="mb-4 cursor-pointer rounded-none border-b-2 border-blue-500 text-blue-500 transition-colors duration-300 ease-in-out hover:border-white hover:text-blue-400"
                href="/property-compliance-subscription/"
              >
                Property Compliance Subscription
              </Link>
              , a subscription-based service that handles all the safety checks
              on your behalf.
            </p>
          </>
        ),
      },
    ],
  },

  {
    title: "Legislation",
    items: [
      {
        id: 151,
        question: "Electrical Safety Activities",
        answer: (
          <>
            <p className="mb-2 text-center text-xl font-bold">
              Residential Tenancies Regulations 2021 - Schedule 3 -
              Safety-related activities
            </p>
            <p className="mb-4">
              The residential rental provider must ensure that an electrical
              safety check of all electrical installations, appliances, and
              fittings provided by a residential rental provider in the rented
              premises is conducted every 2 years by a licensed or registered
              electrician and must provide the renter with the date of the most
              recent safety check, in writing, on request by the renter.
            </p>
            <p className="mb-4">
              If an electrical safety check of the rented premises has not been
              conducted within the last 2 years at the time the renter occupies
              the premises, the residential rental provider must arrange an
              electrical safety check as soon as practicable.
            </p>
            <p className="mb-4">
              On and from 29 March 2023, in relation to electrical safety, all
              power outlets and lighting circuits in the rented premises are to
              be connected to:
            </p>
            <ul className="mb-4 list-disc">
              <li className="mb-2 mr-2 flex h-10 items-center">
                <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />a
                switchboard-type circuit breaker that complies with AS/NZS 3000,
                “Electrical Installations”,
              </li>
              <li className="mb-2 mr-2 flex h-10 items-center font-bold">
                <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                and a switchboard-type Residual Current Device:
              </li>
              <li className="h-18 ml-8 mb-2 mr-2 flex items-center">
                <Icons8ArrowRight className="mr-4 h-7 w-7 flex-shrink-0" />
                AS/NZS 3190, “Approval and test specification—Residual current
                devices (current operated earth-leakage devices)”,
              </li>
              <li className="h-18 ml-8 mb-2 mr-2 flex items-center">
                <Icons8ArrowRight className="mr-4 h-7 w-7 flex-shrink-0" />
                or AS/NZS 61008.1, “Residual current operated circuit-breakers
                without integral overcurrent protection for household and
                similar uses (RCBOs): Part 1: General rules”,
              </li>
              <li className="h-18 ml-8 mb-2 mr-2 flex items-center">
                <Icons8ArrowRight className="mr-4 h-7 w-7 flex-shrink-0" />
                or AS/NZS 61009.1, “Residual current operated circuit-breakers
                with integral overcurrent protection for household and similar
                uses (RCCBs) Part 1: General rules”,
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 152,
        question: "Gas Safety Activities",
        answer: (
          <>
            <p className="mb-2 text-center text-xl font-bold">
              Residential Tenancies Regulations 2021 - Schedule 3 -
              Safety-related activities
            </p>
            <p>
              The safety-related activities in the below subclauses only apply
              if the rented premises contain any appliances, fixtures or
              fittings which use or supply gas:
            </p>
            <ul className="mb-1 list-disc">
              <li className="mb-2 flex h-20 items-center">
                <Icons8checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                The residential rental provider must ensure that a gas safety
                check of all gas installations and fittings in the rented
                premises is conducted every 2 years by a licensed or registered
                gasfitter and must provide the renter with the date of the most
                recent safety check, in writing, on request by the renter.
              </li>
              <li className="mb-2 flex h-20 items-center">
                <Icons8checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                If a gas safety check has not been conducted within the last 2
                years at the time the renter occupies the premises, the
                residential rental provider must arrange a gas safety check as
                soon as practicable.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 153,
        question: "Smoke Alarm Safety Activities",
        answer: (
          <>
            <p className="mb-2 text-center text-xl font-bold">
              Residential Tenancies Regulations 2021 - Schedule 3 -
              Safety-related activities
            </p>

            <ol>
              <li>
                The residential rental provider must ensure that:
                <ul className="mb-1 mt-1 list-disc space-y-2">
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    each smoke alarm is correctly installed and in working
                    condition;
                  </li>
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    each smoke alarm is tested according to the
                    manufacturer&apos;s instructions at least once every 12
                    months;
                  </li>
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    the batteries in each smoke alarm are replaced as required.
                  </li>
                </ul>
              </li>

              <li>
                The residential rental provider must immediately arrange for a
                smoke alarm to be repaired or replaced as an urgent repair if
                they are notified by the renter that it is not in working order.
                <p className="mb-4 mt-4 text-center italic">
                  Note – A suitably qualified person must repair or replace a
                  hard-wired smoke alarm.
                </p>
              </li>

              <li>
                The residential rental provider, on or before the commencement
                of the residential rental agreement, must provide the renter
                with the following information in writing:
                <ul className="mb-1 mt-1 list-disc space-y-2">
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    how each smoke alarm in the rented premises operates;
                  </li>
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    how to test each smoke alarm in the rented premises;
                  </li>
                  <li className="mb-2 mr-2 flex h-10 items-center">
                    <Icon8Checkmark className="mr-4 h-7 w-7 flex-shrink-0" />
                    the renter&apos;s obligations to not tamper with any smoke
                    alarms and to report if a smoke alarm in the rented premises
                    is not in working order.
                  </li>
                </ul>
              </li>

              <li>
                The renter must give written notice to the residential rental
                provider as soon as practicable after becoming aware that a
                smoke alarm in the rented premises is not in working order.
                <p className="mb-4 mt-4 text-center italic">
                  Note – Regulations under the Building Act 1993 require
                  installing smoke alarms in all residential buildings.
                </p>
              </li>
            </ol>
          </>
        ),
      },
    ],
  },
];

export default FaqSectionsData;
